import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import ApiService from "../services/Api";
import { sidebarItems } from "@/constants/global";
import {
  showErrorMessage
} from "@/utils/showError";
Vue.use(VueRouter);

let dashboardPermission = null;
let items = [];

function guardMyRoute(permission) {
  let authorize = null;
  try {
    authorize = localStorage.getItem("userPermissions").includes(permission);
  } catch (err) {
    err;
    authorize = false;
  }

  if (authorize) {
    return true;
  } else {
    return false;
  }
}

const routes = [
  {
    path: "/",
    name: "Login",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ "@/views/auth/Login.vue"),
  },
  {
    path: "/signup",
    name: "Sign Up",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(/* webpackChunkName: "SignUp" */ "@/views/auth/SignUp.vue"),
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../components/auth/ForgotPassword.vue"
      ),
  },
  {
    path: "/email-verify",
    name: "Email Verify",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "EmailVerify" */ "@/views/auth/EmailVerify.vue"
      ),
  },
  {
    path: "/verify",
    name: "verify",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "OTP Verify" */ "../components/auth/Verify.vue"
      ),
  },
  {
    path: "/merchant/reset-password/:token",
    name: "Reset Password",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../components/auth/ResetPassword.vue"
      ),
  },
  {
    path: "/merchant/set-account-password/:email/:token",
    name: "SetAccountPassword",
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("userPermissions")) {
        next({
          name: 'DashboardLayout'
        });
      } else {
        next();
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "ResetPassword" */ "../components/auth/SetFirstTimeLoginPassword.vue"
      ),
  },
  {
    path: "/dashboard-layout",
    name: "DashboardLayout",
    redirect: '/dashboard',
    component: () =>
      import(
        /* webpackChunkName: "TransactionReports" */ "@/layouts/Dashboard.vue"
      ),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        title: "Dashboard",
        component: () =>
          import(
            /* webpackChunkName: "Dashboard" */ "../components/dashboard/Dashboard.vue"
          ),
        meta: {
          title: "Dashboard",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-dashboard-merchant");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/demo",
        name: "Quick Liveness Detection",
        meta: {
          title: "Quick Demo",
        },
        component: () =>
          import(/* webpackChunkName: "Demo" */ "../components/pages/Demo.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("userPermissions")) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/detail-demo",
        name: "Detail Liveness Detection",
        meta: {
          title: "Detail Demo",
        },
        component: () =>
          import(/* webpackChunkName: "Detail" */ "../components/pages/Demo.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("userPermissions")) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/demo-login",
        name: "Demo",
        component: () =>
          import(/* webpackChunkName: "Demo" */ "../components/pages/Demo.vue"),
        beforeEnter: async (to, from, next) => {
          const currentUrl = window.location.href;
          const splitUrl = currentUrl.split("?");
          const accessToken = splitUrl[splitUrl.length - 1];
          await ApiService.PostRequest("merchant/demo-admin-request", {
            temp_token: accessToken,
          })
            .then((response) => {
              const permissions = [];
              if (window.location.hostname === "localhost") {
                localStorage.setItem("token", response.data.result.data.token);
              }
              store.dispatch("LOGIN", response.data.result.data);
              response.data.result.data.roles[0].permissions.forEach(
                (allPermissions) => {
                  permissions.push(allPermissions.name);
                  localStorage.setItem("userPermissions", permissions);
                }
              );
              renderSideBar();
              store.dispatch("SET_SIDEBAR_ITEM", {
                dashboard: dashboardPermission,
                items: items,
              });
              next("/dashboard");
              let allowed = guardMyRoute("perform-demo");
              if (!allowed) {
                next({
                  name: 'Login'
                });
              } else {
                next();
              }
            })
            .catch((err) => {
              showErrorMessage(err);
            });
        },
      },
      {
        path: "/face-match",
        name: "Face Match",
        component: () =>
          import(
            /* webpackChunkName: "OneToOne" */ "../components/pages/OneToOne.vue"
          ),
        meta: {
          title: "Face Match",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-one-to-one-demo");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/face-search",
        name: "Face Search",
        component: () =>
          import(
            /* webpackChunkName: "OneToMany" */ "../components/pages/OneToMany.vue"
          ),
        meta: {
          title: "Face Search",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-one-to-many-demo");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/face-enroll",
        name: "Face Enroll",
        component: () =>
          import(
            /* webpackChunkName: "FaceEnroll" */ "../components/pages/FaceEnroll.vue"
          ),
        meta: {
          title: "Face Enroll",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-face-enrollment");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/age-estimation",
        name: "Age Estimation",
        component: () =>
          import(
            /* webpackChunkName: "AgeVerification" */ "../components/pages/AgeVerification.vue"
          ),
        meta: {
          title: "Age Estimation",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-age-estimation");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      // {
      //   path: "/document-ocr",
      //   name: "Document OCR",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "DocumentOCR" */ "../components/pages/DocumentOCR.vue"
      //     ),
      //   meta: {
      //     title: "Document OCR",
      //   },
      //   beforeEnter: (to, from, next) => {
      //     let allowed = guardMyRoute("view-document-ocr");
      //     if (!allowed) {
      //       next({
      //         name: 'Login'
      //       });
      //     } else {
      //       next();
      //     }
      //   },
      // },
      {
        path: "/transactions",
        name: "Transaction Reports",
        component: () =>
          import(
            /* webpackChunkName: "TransactionReports" */ "../components/pages/TransactionReports.vue"
          ),
        meta: {
          title: "Transactions",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-transactions-merchant");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/billing",
        name: "Billing",
        component: () =>
          import(
            /* webpackChunkName: "TransactionReports" */ "../components/pages/Billing.vue"
          ),
        meta: {
          title: "Billing",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-billing-status");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/transactions/:id",
        name: "Transaction Details",
        component: () =>
          import(
            /* webpackChunkName: "TransactionDetails" */ "../components/pages/ReportDetails.vue"
          ),
        meta: {
          title: "Transaction Details",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-transactions-merchant");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/user-management",
        name: "User Management",
        component: () =>
          import(
            /* webpackChunkName: "UserManagement" */ "../components/pages/UserManagement.vue"
          ),
        meta: {
          title: "User Management",
        },
        beforeEnter: (to, from, next) => {
          let allowed = guardMyRoute("view-user-management-merchant") || guardMyRoute("view-role-permission-merchant");
          if (!allowed) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/settings",
        name: "Settings",
        component: () =>
          import(
            /* webpackChunkName: "Settings" */ "../components/pages/Settings.vue"
          ),
        meta: {
          title: "Settings",
        },
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("userPermissions")) {
            next({
              name: 'Login'
            });
          } else {
            next();
          }
        },
      },
    ],
  },

  {
    path: "/api-keys",
    name: "ApiKeys",
    component: () =>
      import(
        /* webpackChunkName: "ApiKeys" */ "../components/user/ApiKeys.vue"
      ),
    meta: {
      title: "Api Keys",
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("userPermissions")) {
        next({
          name: 'Login'
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/video/:id",
    name: "Video",
    component: () =>
      import(/* webpackChunkName: "Video" */ "../components/pages/Video.vue"),
    meta: {
      title: "Video",
    },
    beforeEnter: (to, from, next) => {
      if (!localStorage.getItem("userPermissions")) {
        next({
          name: 'Login'
        });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const renderSideBar = () => {
  dashboardPermission = Vue.prototype.hasPermission("view-dashboard-merchant");
  const allowedSidebarItems = [];
  sidebarItems.forEach((category) => {
    const allowedCategoryItems = [];

    Object.values(category).forEach((items) => {
      items.forEach(([key, value]) => {
        if (Array.isArray(key)) {
          let hasPermissions = false;
          key.forEach((permissionName) => {
            if (Vue.prototype.hasPermission(permissionName))
              hasPermissions = true;
          });
          if (hasPermissions) allowedCategoryItems.push({ ...value });
        } else if (Vue.prototype.hasPermission(key))
          allowedCategoryItems.push({ ...value });
      });
    });

    if (allowedCategoryItems.length > 0) {
      allowedSidebarItems.push({
        [Object.keys(category)[0]]: allowedCategoryItems,
      });
    }
  });

  items = allowedSidebarItems;
};
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | Portal" : "Facia Portal";
  next();
});
export default router;